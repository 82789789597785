import { Component, Prop, Watch } from 'vue-property-decorator'
import { GForm } from '@/components/forms/GForm'
interface Icon {
  path: string
  color: string
}

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
})
export class Expandable extends GForm {
  @Prop({ type: [String, Number, Object, Array] }) value!: any
  @Prop({ type: String }) title!: string
  @Prop({ type: String }) label!: string
  @Prop({ type: String }) tooltip!: string
  @Prop({
    type: Object,
    default: () => {
      return { path: '', color: '' }
    },
  }) icon!: Icon

  @Prop({ type: Boolean, default: false }) showIcon: boolean
  @Prop({ type: Boolean, default: false }) opened: boolean
  @Prop({ type: Array, default: () => ([]) }) fields: Array<{ path: string, label: string, transform: string | null }>

  panel = -1

  @Watch('opened', { immediate: true }) onChange (val) {
    this.panel = val ? 0 : -1
  }
}
